@charset "UTF-8";

@import "settings";
@import "mixins";

#start-logo {
  margin: 14px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    display: block;
    flex: 1;
    height: 1px;
    background-color: #cfcfcf;
  }
  .logo {
    margin: 0 48px;
    width: 275px;
    height: 113px;
    background: url(../img/start-logo.png) no-repeat 0 0;
  }
}

#start-content {
  display: flex;
  flex-wrap: wrap;
  min-height: 922px;
  .left-col {
    width: 50%;
    background: url(../img/rsb1.jpg) no-repeat top right;
    text-align: center;
  }
  .right-col {
    width: 50%;
    background: url(../img/rsb2.jpg) no-repeat top left;
    text-align: center;
  }
  h2 {
    margin-bottom: 50px;
    padding-top: 414px;
    font-size: 36px;
    font-weight: 600;
    a {
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
    span {
      border-bottom: 2px solid #fff;
    }
  }
  a.btn-start {
    padding: 26px 36px;
    color: #fff;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    line-height: 14px;
    display: inline-block;
    @include border-radius(10px);
    @include transition(all 0.3s ease);
    &.btn-first {
      background-color: #0066cc;
      &:hover {
        background-color: #005ab3;
      }
    }
    &.btn-second {
      background-color: #ff6600;
      &:hover {
        background-color: #dc5901;
      }
    }
  }
}

@media only screen and (max-width: 1429px) {
  #start-content h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1199px) {
  #start-logo .logo {
    width: 240px;
    height: 99px;
    background-size: 100%;
  }
  #start-content h2 {
    font-size: 27px;
  }
}

@media only screen and (max-width: 991px) {
  #start-logo .logo {
    margin: 0 40px;
    width: 220px;
    height: 90px;
  }
  #start-content {
    min-height: 369px;
    .left-col {
      background-size: 100%;
    }
    .right-col {
      background-size: 100%;
    }
    h2 {
      padding-top: 170px;
      font-size: 24px;
    }
    a.btn-start {
      padding: 20px 30px;
      font-size: 18px;
      font-weight: 500;
    }
  }
}

@media only screen and (max-width: 767px) {
  #start-logo .logo {
    margin: 0 30px;
    width: 140px;
    height: 58px;
  }
  #start-content {
    min-height: 277px;
    h2 {
      margin-bottom: 30px;
      padding-top: 120px;
      font-size: 16px;
    }
    a.btn-start {
      padding: 15px 20px;
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 575px) {
  #start-content {
    min-height: inherit;
    .left-col {
      width: 100%;
      min-height: 350px;
    }
    .right-col {
      width: 100%;
      min-height: 350px;
    }
    h2 {
      margin-bottom: 40px;
      font-size: 22px;
    }
  }
}

@media only screen and (max-width: 419px) {
  #start-logo .logo {
    margin: 0 20px; 
  }
  #start-content {
    .left-col {
      min-height: 308px;
    }
    .right-col {
      min-height: 308px;
    }
    h2 {
      font-size: 18px;
    }
  }
}
